// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  //url:'https://10.42.0.40:4040/man',
  //url: 'http://localhost:4040/man', //starting everything  locally in one machine
  url: 'https://bi.otmretail.com/man',
  // url: 'http://10.10.1.109:4040/man', //with ken
  //url: 'http://192.168.0.102:4040/man',


  powerBI: {
    reportBaseURL: 'https://app.powerbi.com/reportEmbed',
    qnaBaseURL: 'https://app.powerbi.com/qnaEmbed',
    tileBaseURL: 'https://app.powerbi.com/embed',
  },
  user_input_countdown: 30,
  screen_idle_time: 7200,
  keywordsTransalation: {
    product_quantity: 'Number of specific product in the sale',
    product_number: 'Quantity of specific product in the sale with MOQ',
    
    product_sale_value: 'Value of specific product in the sale',
    item_quantity: 'Number of specific SKU in the sale',
    item_number: 'Quantity of specific SKU in the sale with MOQ',

    item_sale_value: 'Value of specific SKU in the sale',
    order_quantity: 'Number of Item in the sale',
    order_sale_value: 'Value of the Sale',
    __: 'None',
    product: 'Banding the reward product',
    actual_value: 'The Reward values is passed as  the  actual discount   ',
    percentage: 'The reward value  is treated as percentage',
    otm_loyalty_points: 'The Reward value is passed  to the  customers  OTM wallet',
    manufacturer_loyalty_points: 'The reward value  is  passed  to the  Manufacturer Loyalty Program'
  },
  customer_types: ['Distributor', 'Wholesaler', 'Kiosk', 'Consumer', 'OTM Wholesaler']

};
