import { Requestembedtoken } from './../layouts/dashboardpowerbi/dashboardpowerbi.component';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FixedDiscount, Portalaccount } from '../models/manportal';
import { BehaviorSubject, Observable } from 'rxjs';
import { Salesteam } from '../sales/salesrep/salesrep.component';
import { Vanshop } from '../sales/vanshops/vanshops.component';
import { environment } from '../../environments/environment';
import { manufacturerProduct, Todayssales, manufacturerSku, manufacturerRegions, RegionTown, Region } from '../layouts/otmdashboard/dashboard.component';

import { AssignManufacturerProducts } from '../sales/salesrep-products/salesrep-products.component';
import { AssignManproducts } from '../sales/assign-vanproducts/assign-vanproducts.component';
import { forgotpassword, passwordreset } from '../modules/preauth/components/reset-page/reset-page.component';
import { PromoDetails } from '../sales/promotions/promotions.component';
import { manPromoProducts } from '../sales/promotions/productpromo/productpromo.component';
import { userprofile } from '../modules/post_auth/components/otm-userprofile/otm-userprofile.component';
import { assignwholesaler } from '../sales/assignwholesale/assignwholesale.component';
import { Apptheme, CustomerGroup, GroupDiscount, GroupMessage, Groupmembers, Mancode, ManufacturerCustomer, PageObject, smsConfig } from '../manufacturer-distribution/customer-groups/models/customer-model';



@Injectable({
  providedIn: 'root'
})
export class PostauthService {
  pageBehavour:BehaviorSubject<PageObject>|undefined;
  public smsconfig_subject:BehaviorSubject<Array<smsConfig>>
  public session_expired_subject:BehaviorSubject<boolean>
  public smsconfig_array:Array<smsConfig>
  public remoteUrl: string;
  public manaufacturer: string;
  public assigned_data: any;
  public weeks: Array<string>;
  public months: Array<{}>;
  public years: Array<string>;
  public currentUser: Portalaccount;
  public viewtitle: string;
  public data_on_localstorage: any;
  public user_on_localstorage: any;
  public Userid_assignedProduct: string;
  public promoId: string;
  public lastnetworkcall: number;
  public currentCustomer: BehaviorSubject<ManufacturerCustomer>;
  public currentSmsConfig: BehaviorSubject<smsConfig>
  public region_town: BehaviorSubject<manufacturerRegions>
  public updated_group_subject: BehaviorSubject<CustomerGroup>
  public updated_customer_subject: BehaviorSubject<ManufacturerCustomer>
  public discounts_subject: BehaviorSubject<Array<GroupDiscount>>
  public members_subject: BehaviorSubject<Array<Groupmembers>>
  public all_customers_subject: BehaviorSubject<Array<ManufacturerCustomer>>
  public selected_customer_group:BehaviorSubject<CustomerGroup>
  public current_message_event_details:BehaviorSubject<MessageEvent>
  public current_town:BehaviorSubject<RegionTown>
  public selected_region:BehaviorSubject<Region>
  public selected_discount_sbj:BehaviorSubject<GroupDiscount>
  public getAssignedDataKeys() {

    return Object.keys(this.assigned_data)
      .filter(ob => { return (ob !== "0") })
      .map((nm) => {
        let item = nm.trim().toLowerCase();
        switch (item) {
          case 'manufacture': {
            item = 'manufacturer';
            break;
          }
          case 'categorie': {
            item = 'category';
            break;
          }
          case 'subcategorie': {
            item = 'subcategory';
            break;
          }
          case 'prodcutlabel': {
            item = 'Brand';
            break;
          }
          case 'products': {
            item = 'Brand';
            break;
          }
          case 'regions': {
            item = 'region';
            break;
          }
          case 'sku': {
            item = 'SKU';
            break;
          }

        }
        return item;
      });
  }

  public getAssignedDataKeysRaw() {
    return Object.keys(this.assigned_data);
  }

  constructor(private http: HttpClient) {
    this.pageBehavour=new BehaviorSubject({title:''});
    this.selected_discount_sbj = new BehaviorSubject(undefined)
    this.smsconfig_subject = new BehaviorSubject(new Array());
    this.session_expired_subject=new BehaviorSubject(false);
    this.smsconfig_array = new Array();
    this.discounts_subject = new BehaviorSubject(undefined)
    this.remoteUrl = environment.url;
    this.currentUser = undefined;
    this.weeks = new Array();
    this.currentCustomer = new BehaviorSubject(undefined);
    this.currentSmsConfig = new BehaviorSubject(undefined);
    this.region_town = new BehaviorSubject(undefined)
    for (let index = 1; index < 52; index++) {
      this.weeks.push(index.toString());
    }

    this.months = new Array();

    this.months.push({ name: 'January', num: '1' });
    this.months.push({ name: 'Feb', num: '2' });
    this.months.push({ name: 'March', num: '3' });
    this.months.push({ name: 'April', num: '4' });
    this.months.push({ name: 'May', num: '5' });


    this.years = new Array();
    for (let index = 2018; index < (new Date().getFullYear() + 1); index++) {
      this.years.push(index.toString());
    }

    this.updated_group_subject = new BehaviorSubject(undefined)
    this.updated_customer_subject = new BehaviorSubject(undefined)
    this.members_subject = new BehaviorSubject(undefined)
    this.all_customers_subject = new BehaviorSubject(new Array())
    // this.customer_list_subject = new BehaviorSubject(new Array())
    this.selected_customer_group = new BehaviorSubject(undefined)
    this.current_message_event_details = new BehaviorSubject(undefined)
    this.current_town = new BehaviorSubject(undefined)
    this.selected_region = new BehaviorSubject(undefined)
  }
  sendskucode(items):Observable<any>{
    return this.http.post(this.remoteUrl +'products' + this.currentUser.session + 'sendSKUS',items)
  }
  add_to_focus_brand(items):Observable<any>{
    return this.http.post(this.remoteUrl +'products' + this.currentUser.session + 'sendSKUS',items)
  }
  //get group messages
  get_group_messages(group_id:string):Observable<any>{
 
  return this.http.post(this.remoteUrl + '/messages/'+ this.currentUser.session + '/get_group_messages',{'group_id':group_id})
  }
  
  //get_group_messages_by_date
  get_group_messages_by_date(group_id:string,date:string):Observable<any>{
  return this.http.post(this.remoteUrl + '/messages/'+ this.currentUser.session + '/get_group_messages_by_date',{group_id:group_id,date:date})
  }
  
  //get_group_messages_by_date_range
  get_group_messages_by_date_range(group_id:string,start_date:string,end_date:string):Observable<any>{
  return this.http.post(this.remoteUrl + '/messages/'+ this.currentUser.session + '/get_group_messages_by_date_range',{group_id:group_id,start_date:start_date,end_date:end_date})
  }
  //get_event_messages
  get_event_messages(group_id:string, event_id:string):Observable<any>{
  return this.http.post(this.remoteUrl + '/messages/'+ this.currentUser.session + '/get_event_messages',{group_id:group_id,event_id:event_id})
  }
   
  //add town to region
  add_town_to_region(newtown:any):Observable<any>{
    console.log(newtown);
    return this.http.post(this.remoteUrl + '/manageregions/' +this.currentUser.session + '/add_region_town',newtown)
  }


   //list sms configurations
   list_sms_configurations():Observable<any> {

    return this.http.post(this.remoteUrl + '/smsconfiguration/' + this.currentUser.session + '/view_bi_sms_cofiguration',{});
  }
  //sms configurations
   add_smsConfig(smsConfig: smsConfig):Observable<any> {

    return this.http.post(this.remoteUrl + '/smsconfiguration/' + this.currentUser.session + '/add_sms_config',smsConfig);
  }
  edit_smsConfig(new_smsConfig:smsConfig): Observable<any>{
    return this.http.post(this.remoteUrl + '/smsconfiguration/' + this.currentUser.session + '/edit_sms_config',new_smsConfig);
  }
  delete_smsConfig(smsConfig: smsConfig): Observable<any>{
    return this.http.post(this.remoteUrl + '/smsconfiguration/' + this.currentUser.session + '/delete_sms_config',smsConfig);
  }

  fetchData(postdata: {}, reporttype: string) {
    this.lastnetworkcall = Date.now();
    return this.http.post(this.remoteUrl + '/reports/' + reporttype + '/' + this.currentUser.session, postdata);
  }
  setthemeColor(themeObj:Apptheme):Observable<any>{
    return this.http.post(this.remoteUrl + '/theme/' + this.currentUser.session + '/theme', themeObj)

  }
  sendGroupMessage(messageObj: GroupMessage, id: string) {
    this.lastnetworkcall = Date.now();


    return this.http.post(this.remoteUrl + '/messages/' + this.currentUser.session + '/group_messages', messageObj)
  }

  addMobileProfile(salerep: Salesteam) { 
    this.lastnetworkcall=Date.now();
    switch(salerep.profile_type){
      case 'sr':{
        return this.http.post(this.remoteUrl + '/salesrep/' + this.currentUser.session+'/addsalesrep', salerep );
        break;}
      case 'ba':{
        return this.http.post(this.remoteUrl + '/salesrep/' + this.currentUser.session+'/createbrandambassador' , salerep );
        break;}
    }
    
}

  getSalesrep(operationtype: string):Observable<Salesteam[]>{
    this.lastnetworkcall=Date.now();
  return this.http.get<Salesteam[]>(this.remoteUrl + '/salesrep/' + this.currentUser.session+'/' +operationtype);

}

updateSalesrep(salerep: Salesteam, operationtype: string){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/salesrep/' + this.currentUser.session +'/' + operationtype,  salerep);

}
deleteSalesrep(salesrepid: string ){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/salesrep/' + this.currentUser.session +'/deletesalesrep/',{salesrepid:salesrepid});

}




addVanShop(vshop: Vanshop,operationtype: string  ){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/vanshop/' + this.currentUser.session+ '/' +operationtype ,vshop);

}
getVanShop(operationtype: string):Observable<Vanshop[]>{
  this.lastnetworkcall=Date.now();
  return this.http.get<Vanshop[]>(this.remoteUrl + '/vanshop/' + this.currentUser.session+'/' +operationtype);

}

updateVanShop(vshop: Vanshop, operationtype: string){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/vanshop/' + this.currentUser.session +'/' + operationtype,  vshop);

}

deleteVanShop(vanid:string ){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/vanshop/' + this.currentUser.session+'/deletevan/',{vanid:vanid});

}
getManufacturerSkus(operationtype: string):Observable<manufacturerSku[]>{
  this.lastnetworkcall=Date.now();
  return this.http.get<manufacturerSku[]>(this.remoteUrl + '/dashboard/' + this.currentUser.session+'/' +operationtype)
}
getManufacturerProducts(operationtype: string):Observable<manufacturerProduct[]>{
  this.lastnetworkcall=Date.now();
  return this.http.get<manufacturerProduct[]>(this.remoteUrl + '/dashboard/' + this.currentUser.session+'/' + operationtype)
}

getManProducts(operationtype: string):Observable<manufacturerProduct[]>{
  this.lastnetworkcall=Date.now();
  return this.http.get<manufacturerProduct[]>(this.remoteUrl + '/manageproducts/' + this.currentUser.session+'/' + operationtype)
}

//manageproducts/{sessionid}/{operation}
updateUser( user: userprofile,operationtype: string ){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl +'/userprofile/' + this.currentUser.session+'/' +operationtype , user);
}

getUser(operationtype: string):Observable<userprofile[]>{
  this.lastnetworkcall=Date.now();
  return this.http.get<userprofile[]>(this.remoteUrl +'/userprofile/' + this.currentUser.session+'/' +operationtype);

}
getDailyssales(operationtype: string):Observable<Todayssales[]>{
  this.lastnetworkcall=Date.now();
  return this.http.get<Todayssales[]>(this.remoteUrl + '/dashboard/' + this.currentUser.session+'/' +operationtype)
}
resetPassword(resetpass: passwordreset){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl +'/resetpassword/' + this.currentUser.session,resetpass);
}
assignProducts(assignprod: AssignManproducts, operationtype: string ){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl+'/assign-products/'+this.currentUser.session+'/' + operationtype,assignprod);
}
UnassignedProducts(product: string ){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/assign-products/' + this.currentUser.session +'/unassignproduct',{product:product});

}
assignsalesrepProduct(assignmanprod:AssignManufacturerProducts){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl+'/assign-salerepproducts/'+"0f8632d1-da17-44ba-957a-3c17c4462ab5",assignmanprod);
}
forgotPassword(forgotpass: forgotpassword){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl +'/forgotpassword/',forgotpass);
}
assignsalerep_wholesaler(assignwholesaler: assignwholesaler,operationtype: string){
  this.lastnetworkcall=Date.now();
  console.log(assignwholesaler)
  return this.http.post(this.remoteUrl +'/assignwholesaler/' + this.currentUser.session+ '/'+ operationtype,assignwholesaler);
}
deAssignsalerep_wholesaler(salesrep_id: string, operationtype: string){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/assignwholesaler/' + this.currentUser.session +'/' + operationtype,  salesrep_id);

}

promotions(promo:PromoDetails ,operationtype: string  ){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/promotions/' + this.currentUser.session+ '/' +operationtype ,promo);

}

getPromotions(operationtype: string):Observable<PromoDetails[]>{
  this.lastnetworkcall=Date.now();
  return this.http.get<PromoDetails[]>(this.remoteUrl + '/promotions/' + this.currentUser.session+'/' +operationtype);

}

selectedPromotion(promoid: string, operationtype: string ){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/promotions/' + this.currentUser.session +'/' +operationtype,{promoid:promoid});

}

promoProducts(promoprod: manPromoProducts, operationtype: string ){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl+'/promotions/'+this.currentUser.session+'/' + operationtype,promoprod);
}

getPromotedProduct(promoid: string, operationtype: string):Observable<manPromoProducts[]>{
  this.lastnetworkcall=Date.now();
  return this.http.post<manPromoProducts[]>(this.remoteUrl + '/promotions/' + this.currentUser.session+'/' +operationtype,{promoid:promoid} );
}

onUpadateCart(vanid:string ){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/assign-products/' + this.currentUser.session+'/updatecartvan/',{vanid:vanid});
}

// PromoProduct(promoid: string ){
//  return this.http.post(this.remoteUrl + '/promotions/' + this.currentUser.session +'/fetchpromotedproduct/',{promoid:promoid});

// }
updatePowerBiReportconfigs(embedconfig: Requestembedtoken){
  this.lastnetworkcall=Date.now();
  return this.http.post(this.remoteUrl + '/dashboardbi/' + this.currentUser.session, embedconfig)
}
// getPowerBiReportconfigs():Observable<Requestembedtoken[]>{
//   return this.http.get<Requestembedtoken[]>(this.remoteUrl + '/dashboardbi/' + this.currentUser.session);
// }

checkSessionExpiry(){
  let sessionExpiry;
  let sessionExpired = false
  sessionExpiry= (Math.floor(Date.now()/1000) - Math.floor(this.lastnetworkcall/1000))
  // alert(Math.floor(this.lastnetworkcall/1000))
  // alert(Math.floor(Date.now()/1000))
  if (sessionExpiry>300){
    sessionExpired = true
  }else {
    sessionExpired = false
 }
 return sessionExpired
}

clearPostauthData(){
  this.assigned_data=undefined
this.weeks=[]
  this.months=[]
this.years=[]
 this.currentUser=undefined
this.viewtitle=''
this.data_on_localstorage =undefined
 this.user_on_localstorage =undefined
this.Userid_assignedProduct=''
this.promoId=''
this.lastnetworkcall=0

}

//add priceband definition
createPriceBand(bandInfor:any):Observable<any>{
  console.log("Outgoing data>>>" + JSON.stringify(bandInfor));
  return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/add_priceband_definition/', bandInfor);
}

//retrieves band definitions
getBands():Observable<any>{
  console.log("chap chap")
  return this.http.get(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/get_priceband_definitions/');
}

//Add Region
addRegion(regionData:any):Observable<any>{
  return this.http.post(this.remoteUrl + '/manageregions/' + this.currentUser.session + '/add_region',regionData);
}
//Edit Region
editRegion(regionData:any):Observable<any>{
  return this.http.post(this.remoteUrl + '/editregions/' + this.currentUser.session + '/edit_region',regionData);
}

//get manufacturer regions
getManufacturerRegions(manufacturerCode:any):Observable<any>{
 return this.http.get(this.remoteUrl + '/manageregions/' + this.currentUser.session +'/get_regions/',manufacturerCode);
}

//Add Town
addTown(regionData:any):Observable<any>{
  return this.http.post(this.remoteUrl + '/manageregions/' + this.currentUser.session + '/add_region_town',regionData);
}

//get manufacturer regions towns
getManufacturerRegionTown(regionId:any):Observable<any>{

  return this.http.post(this.remoteUrl + '/manageregions/' + this.currentUser.session +'/get_regions_towns/',{region_id:regionId});
 }

 //get distribution partner data
 fetchDistributorData(partnerInfor:any):Observable<any>{
  return this.http.post(this.remoteUrl + '/managedistributionpartners/' + this.currentUser.session +'/distribution_partnerby_id/',partnerInfor);
 }

 //update distributor partner data.
 updateDistributorData(partnerInfor:any):Observable<any>{
  return this.http.post(this.remoteUrl + '/managedistributionpartners/' + this.currentUser.session +'/update_distribution_partner/',partnerInfor);
 }

//add Distribution partner
addDistributionPartner(partner:any):Observable<any>{
  return this.http.post(this.remoteUrl + '/managedistributionpartners/' + this.currentUser.session + '/add_distribution_partner',partner);
}
//add Customer
registerCustomer(customer:any):Observable<any>{
  customer.fullfillmentPartner=customer.fullfillment_partner.toString();
  console.log(customer);
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/create_manufacturer_customer',customer);
}
//edit  customer
editCustomer(customer:any):Observable<any>{
  customer.fullfillmentPartner=customer.fullfillment_partner.toString();
  console.log(customer);
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/edit_manufacturer_customer',customer);
}

uploadCustomer(customer: any): Observable<any> {
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + 'upload_manufacturer_customer', customer);
}

deleteCustomer(mancode:string,customerid:string):Observable<any>{
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/delete_manufacturer_customer',{mancode:mancode,customerid:customerid});
}



//list Customer
listManufacturerCustomers():Observable<any>{
  return this.http.get(this.remoteUrl + '/customers/' + this.currentUser.session + '/list_manufacturer_customers');
}

//add CustomerGroup
registerCustomerGroup(partner:any,pricelist:unknown):Observable<any>{
  partner['pricelist']=pricelist;
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/add_customer_group',partner);
}
//edit CustomerGoup
editCustomerGroup(partner:any, pricelist:unknown): Observable<any>{
  partner['pricelist']=pricelist;
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/edit_customer_group',partner);
  
}
//delete CustomerGoup
deleteCustomerGroup(partner:any): Observable<any>{
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/delete_customer_group',partner);
 }

//fetch CustomerGroup
getCustomerGroup():Observable<any>{
  return this.http.get(this.remoteUrl + '/customers/' + this.currentUser.session + '/fetch_customer_group');
}
//add CustomerGroup
assignCustomerToCustomerGroup(group:unknown,customer:unknown):Observable<any>{
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/assign_customer_to_customer_group',{group_id:group,customer_id:customer});
}
assignMultipleCustomerToCustomerGroup(group:unknown,customer:unknown):Observable<any>{
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/assign_multiple_customer_to_customer_group',{group_id:group,customer_id:customer});
}
//remove Customer from customer  group
removeCustomerFromCustomerGroup(group_id:unknown,customer_id:unknown):Observable<any>{
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/delete_customer_from_customer_group',{group_id:group_id,customer_id:customer_id});
}
//add CustomerGroup
assignDiscountToCustomerGroup(group:unknown,discount:unknown):Observable<any>{
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/assign_discount_to_customer_group',{group_id:group,discount_id:discount});
}
//remove Discount from customer  group
removeDiscountFromCustomerGroup(group_id:unknown,discount_id:unknown):Observable<any>{
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/delete_discount_from_customer_group',{group_id:group_id,discount_id:discount_id});
}

//fetch CustomerGroup details
getCustomerGroupMemebersAndDiscounts(group_id:string):Observable<any>{
  return this.http.post(this.remoteUrl + '/customers/' + this.currentUser.session + '/fetch_customer_group_details',{customer_group_id:group_id});
}


//get manufacturer region distribution partners
getDistributionPartners():Observable<any>{
  return this.http.get(this.remoteUrl + '/managedistributionpartners/' + this.currentUser.session + '/get_distribution_partners');

}

//get manufacturer region distribution partners
getDistributionPartnersByRegion(regionIdentity):Observable<any>{
  return this.http.post(this.remoteUrl + '/managedistributionpartners/' + this.currentUser.session + '/get_distribution_partner_by_region/',regionIdentity);

}

//get skuBanding infor
getSkuBandPricing(skucode):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/sku_price_band_pricing/',skucode);
}

//set band selling price
setBandSellingPrices(bandPricingInfo):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/priceband_pricing/',bandPricingInfo);
   
}

//removeSkuRegionalBandPricing
removeSkuRegionalBandPricing(bandPricingInfo):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/remove_skuband_region_pricing/',bandPricingInfo);
   
}


//fetch all bands assigned to an sku
// skuAssignedBands(skuInfor):Observable<any>{
//   return this.http.post(this.remoteUrl + '/managepricebands/' + this.currentUser.session + '/sku_assigned_bands/',skuInfor);
// }

//delete price band
deletePriceBandDefinition(bandInfor):Observable<any>{
 return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/delete_priceband_definition/',bandInfor);
}

//Delet band def confirmation incase the band has pricing data.
deletePriceBandDefinitionConfirmed(isConfirmed:any):Observable<any>{
  console.log("Outgoing obj after delete is confirmed: " + JSON.stringify(isConfirmed));
  return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/delete_priceband_definition_confirmed/',isConfirmed);
 }
//delete town
deleteTown(town):Observable<any>{
  return this.http.post(this.remoteUrl + '/deleteRegionTown/' + this.currentUser.session +'/delete_town/',town);
}
updateTown(newTown):Observable<any>{
  return this.http.post(this.remoteUrl + '/updateRegionTown/' + this.currentUser.session +'/update_town/',newTown);
}
//delete region
deleteRegion(regionInfor):Observable<any>{
  return this.http.post(this.remoteUrl + '/manageregions/' + this.currentUser.session +'/delete_region/',regionInfor);
}

//get region distribution partners
regionDistPartners(regionInfor):Observable<any>{
  return this.http.post(this.remoteUrl + '/managedistributionpartners/' + this.currentUser.session +'/partner_region/',regionInfor);
}

//delete distribution partner
deleteDistributionPartner(partnerInfor):Observable<any>{
  return this.http.post(this.remoteUrl + '/managedistributionpartners/' + this.currentUser.session + '/delete_distribution_partner/',partnerInfor);
}

//Get the set sku bands with their pricing and othre details like the region for each band
getSetSkuBands(skuInfor:{}):Observable<any>{
return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/sku_band_prices/',skuInfor);
}

//set distributor band prices.
//partner_id
setDistributorBandPrices(bandPrice):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/add_distributor_sku_bandprice/',bandPrice);
}

//distributor product pricing
distributorProductPricing(partnerInfor):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/dist_partner_price_list/',partnerInfor);
}


updateDistributorSkuBandPrice(pricingData):Observable<any>{
  console.log("Outgoing pricing data " + JSON.stringify(pricingData));
  return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/update_dist_skuband_price/',pricingData);
}

//assign region price to distributor sku band price
assignRegionPrice(skuInfor):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepriceband/' + this.currentUser.session + '/assignsku_region_price/',skuInfor);
}

getManufacturerPriceList(manCode:String):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session + '/get_price_lists/',manCode);
}

getSkuMasterPriceList(manCode:any):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session + '/get_master_pricelist/',manCode);
}

//addPriceListItem
addPriceListItem(priceListItem):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session + '/add_price_listitem/',priceListItem);
}

getSkuPriceList(priceListData):Observable<any>{

  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session + '/get_pricelist_skus/',priceListData);
}

getPricelistTypes(manCode):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session + '/get_pricelist_types/',manCode);
}

createPricelistType(listData):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session + '/add_pricelist_definition/',listData);
}
updatePricelistType(listData):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session + '/update_pricelist_definition/',listData);
}

deletePricelistType(priceList ){
  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session +'/price_list_deletion/',priceList);

}
//remove_from_pricelist
removeSkuFromPricelist(data):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session + '/remove_from_pricelist/',data);
}

//get stores
fetchStores(data):Observable<any>{
  return this.http.post(this.remoteUrl + '/managedistributionpartners/' + this.currentUser.session + '/get_otm_agents/',data);
}


//get stores
fetchOtmStore():Observable<any>{
  return this.http.post(this.remoteUrl + '/managedistributionpartners/' + this.currentUser.session + '/fetch_otm_wholesellers/',{});
}



//get region distribution partners
fetchDistAgents(regionInfor):Observable<any>{
  return this.http.post(this.remoteUrl + '/managedistributionpartners/' + this.currentUser.session +'/get_distribution_partners/',regionInfor);
}

//update product data
editProductData(productData):Observable<any>{
  return this.http.post(this.remoteUrl + '/manageproducts/' + this.currentUser.session + '/edit_product/',productData);
}

//update sku data
editSkuData(skuData):Observable<any>{
  return this.http.post(this.remoteUrl + '/manageproducts/' + this.currentUser.session + '/edit_sku/',skuData);
}


//publish pricelist changes
publishSkuPricelist(pricelist):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session + '/publish_pricelist_updates/',pricelist);
}
publishEntirePricelist(pricelistdata):Observable<any>{
  return this.http.post(this.remoteUrl + '/managepricelist/' + this.currentUser.session + '/publish_entire_pricelist/',pricelistdata);
}

//create fixed  discount
createDiscount(discount:any,discount_type:string):Observable<any>{
  console.log(this.remoteUrl + '/discounts/' + this.currentUser.session + '/'+discount_type);
  return this.http.post(this.remoteUrl + '/discounts/' + this.currentUser.session + '/'+discount_type,discount);
}

editDiscount(discount:any):Observable<any>{
  return this.http.post(this.remoteUrl + '/discounts/' + this.currentUser.session + '/edit_discount',discount);
}

listDiscounts():Observable<any>{
  return this.http.post(this.remoteUrl + '/discounts/' + this.currentUser.session + '/list_discount',{});
}

deleteDiscount(discount_id:string):Observable<any>{
  return this.http.post(this.remoteUrl + '/discounts/' + this.currentUser.session + '/delete_discount',{id:discount_id});
}

assignPartnerDiscounts(partner:string,discount:string):Observable<any>{
  return this.http.post(this.remoteUrl + '/discounts/' + this.currentUser.session + '/assign_partner_discount',{partner:partner,discount:discount});
}
removePartnerDiscounts(discountassignment):Observable<any>{
  return this.http.post(this.remoteUrl + '/discounts/' + this.currentUser.session + '/deassign_partner_discount',{partner:discountassignment.partner_id,discount:discountassignment.discount_id});
}

remove_empty_string(data:string,defaultString:string){
  if(data!=undefined&&data.length!==0){
    return data;
  }else{
    return defaultString;
  }
}


}
