import { DatePipe } from '@angular/common';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Item } from 'angular2-multiselect-dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TargetLocator } from 'selenium-webdriver';
import { manufacturerProduct } from 'src/app/layouts/otmdashboard/dashboard.component';
import { GroupDiscount } from 'src/app/manufacturer-distribution/customer-groups/models/customer-model';
import { DiscounRewardTypes, DiscounTriggerTypes, DiscountValueTypes, FixedDiscount } from 'src/app/models/manportal';
import { NotificationService } from 'src/app/services/notification.service';
import { PostauthService } from 'src/app/services/postauth.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit {
  public pageno:number
  private fixed_discount: FixedDiscount;
  public  discountForm: FormGroup;
  public band_defination_form: FormGroup;
  private dvt: Array<string>;
  public  discount_trigger_types: Array<string>;
  public discount_reward_types: Array<string>;
  private discountList: Array<FixedDiscount>;
  public activediscounts: Array<GroupDiscount>;
  public activediscounts_filtered:Array<GroupDiscount>
  public mydiscounts: Array<GroupDiscount>
  private products: manufacturerProduct[];
  private current_bands: {}[];
  private last_lower_band: Number;
  private last_upper_band: Number;
  public selected_discount:GroupDiscount
  bsModalRef: BsModalRef;
  public discounttypes:DiscountValueTypes
  @ViewChild("discountType") discount_type_element: ElementRef;
searchString: String;
  constructor(private dialog: MatDialog, public activatedroute: ActivatedRoute,
    public datePipe: DatePipe, public postauth: PostauthService,
    public notiservice: NotificationService, private modalService: BsModalService) {
    this.dvt = Object.keys(DiscountValueTypes).filter((a) => (a.length > 1));
    this.discount_trigger_types = Object.keys(DiscounTriggerTypes).filter((a) => (a.length > 1))
    this.discount_reward_types = Object.keys(DiscounRewardTypes).filter((a) => (a.length > 1))
    this.discountList = new Array();
    this.current_bands = new Array();
    this.discountForm = new FormGroup({
      discountType: new FormControl('',),
      rewardType: new FormControl('', Validators.required),
      rewardValue: new FormControl(''),
      rewardItem: new FormControl('',),
      triggerType: new FormControl('', Validators.required),
      triggerProduct: new FormControl(''),
      name: new FormControl('', Validators.required),
      id: new FormControl('')
    })
    this.band_defination_form = new FormGroup({
      lower_band: new FormControl('', Validators.required),
      upper_band: new FormControl('', [Validators.required]),
      rewardValue: new FormControl('', Validators.required),
      band_name: new FormControl('', Validators.required),

    })

  }

  ngOnInit(): void {

    console.log('fixed  discount  component  caleld');

    this.activatedroute.data.subscribe(data => {
      console.log(data)
      this.products = data.products['response'];
      this.activediscounts = data.discounts['response'];
      this.postauth.discounts_subject.next(this.activediscounts)
    })

  
    this.postauth.discounts_subject.subscribe(success=>{
      console.log(success)
      this.mydiscounts=success;
      this.activediscounts_filtered = this.mydiscounts
    })

    this.postauth.selected_discount_sbj.subscribe(activeDiscount=>{
      this.selected_discount = activeDiscount
    })

    // this.discountForm.setValue({
    //   discountType: this.selected_discount.discountType,
    //   rewardType: this.selected_discount.rewardType,
    //   rewardValue: this.selected_discount.rewardValue,
    //   rewardItem: this.selected_discount.rewardItem,
    //   triggerType: this.selected_discount.triggerType,
    //   triggerProduct: this.selected_discount.triggerProduct,
    //   name: this.selected_discount.name,
    //   id: this.selected_discount.id
    // })
  }

  addBand() {

    console.log(this.band_defination_form.value);
    if (this.band_defination_form.valid) {
      this.current_bands.push(this.band_defination_form.value);
      this.band_defination_form.reset();
    } else {

    }

  }


  filter_disscounts(searchstring: String) {
    this.activediscounts_filtered = this.mydiscounts.filter((disc) => {
      return (disc['name'].toLowerCase().indexOf(searchstring.toLowerCase()) > -1);
    });


  }

  removeBand(band_name: String) {
    this.current_bands = this.current_bands.filter((a) => (a['band_name'] !== band_name))
  }

  openModal(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template);
  }

  resetDiscount() {
    this.discountForm.reset();
    this.band_defination_form.reset();
  }

  getTransalatedKey(key: string) {
    let transalated = environment.keywordsTransalation[key];
    if (transalated === undefined) {
      return key;
    } else {
      return transalated;
    }
  }

  createDiscount() {
    this.discountForm.value['discountType'] = this.discount_type_element.nativeElement.innerHTML;
    switch (this.discount_type_element.nativeElement.innerHTML) {
      case 'Product Material Discount': {
        this.postauth.createDiscount(this.discountForm.value, "create_material_discount").subscribe(suc => {
          console.log(suc);
          if(suc['response']==true){
            this.mydiscounts.unshift(this.discountForm.value)
            this.postauth.discounts_subject.next(this.mydiscounts)
          this.notiservice.notify('success',"Discount Created");
          this.discountForm.reset();

          }
        }, error => { 
          this.notiservice.notify('error',"Error Creating  Discount");
        });
        break;
      }
      case 'Product Discount': {
        console.log(this.discountForm.value)
        console.log(this.current_bands)
        this.postauth.createDiscount({ discount: this.discountForm.value, bands: this.current_bands }, "create_product_discount").subscribe(suc => {
          console.log(suc);
          if(suc['response']==true){
            this.mydiscounts.unshift(this.discountForm.value)
            this.postauth.discounts_subject.next(this.mydiscounts)
          this.notiservice.notify('success',"Discount Created");
          this.discountForm.reset();

          }
        }, error => { 
          this.notiservice.notify('error',"Error Creating  Discount");
        });
        break;
      }
      case 'SKU Discount': {
        console.log(this.discountForm.value)
        console.log(this.current_bands)

        this.postauth.createDiscount({ discount: this.discountForm.value, bands: this.current_bands }, "create_sku_discount").subscribe(suc => {
          console.log(suc);
          if(suc['response']==true){
            this.mydiscounts.unshift(this.discountForm.value)
            this.postauth.discounts_subject.next(this.mydiscounts)
          this.notiservice.notify('success',"Discount Created");
          this.discountForm.reset();

          }
        }, error => { 
          this.notiservice.notify('error',"Error Creating  Discount");
        });
        break;
      }
      case 'Material Discount': {
        this.postauth.createDiscount(this.discountForm.value, "create_material_discount").subscribe(suc => {
          console.log(suc);
          if(suc['response']==true){
            this.mydiscounts.unshift(this.discountForm.value)
            this.postauth.discounts_subject.next(this.mydiscounts)
          this.notiservice.notify('success',"Discount Created");
          this.discountForm.reset();

          }
        }, error => { 
          this.notiservice.notify('error',"Error Creating  Discount");
        });
        break;
      }
      case 'Quantity Discount': {
        this.postauth.createDiscount({ discount: this.discountForm.value, bands: this.current_bands }, "create_quantity_discount").subscribe(suc => {
          console.log(suc);
          if(suc['response']==true){
            this.mydiscounts.unshift(this.discountForm.value)
            this.postauth.discounts_subject.next(this.mydiscounts)
            this.notiservice.notify('success',"Discount Created");
            this.discountForm.reset();
            
                      }
        }, error => { 
          this.notiservice.notify('error',"Error Creating  Discount");
        });
        break;
      }
      case 'Fixed Discount': {
        this.postauth.createDiscount(this.discountForm.value, "create_fixed_discount").subscribe(suc => {
          console.log(suc);
          if(suc['response']==true){
            this.mydiscounts.unshift(this.discountForm.value)
            this.postauth.discounts_subject.next(this.mydiscounts)
            this.notiservice.notify('success',"Discount Created");
            this.discountForm.reset();
            
                      }
        }, error => { 
          this.notiservice.notify('error',"Error Creating  Discount");
        });
        break;
      }
      default: {
        alert('going  to defualt  route' + this.discount_type_element.nativeElement.innerHTML);
      }
       


    }
  }

  deleteDiscount(discount:GroupDiscount) {

    this.postauth.deleteDiscount(discount.id).subscribe(suc => {
      console.log(suc);
      if (suc['response'] == true) {
        this.notiservice.notify("success", "Discount deleted");
        this.activediscounts_filtered=this.activediscounts_filtered.filter((disc)=> disc['id'] !== discount.id)
        // this.activediscounts = this.activediscounts.filter((dis) => dis['id'] !== id);
      }
     
    }, error => { });;
  }

  editDiscount(discount) {
    console.log(discount)
    this.postauth.selected_discount_sbj.next(discount)
    this.postauth.selected_discount_sbj.subscribe(success=>{
      this.selected_discount = success

    })
   
    console.log('loop two')
    console.log(this.discountForm.value['discountType'])
    this.discountForm.setValue({
      discountType: discount['discountType'],
      rewardType: discount['rewardType'],
      rewardValue: discount['rewardValue'],
      rewardItem: discount['rewardItem'],
      triggerType: discount['triggerType'],
      triggerProduct: discount['triggerProduct'],
      name: discount['name'],
      id: discount['id']
    })
    console.log('after setvalue')
    console.log(this.discountForm.value)
    //console.log(this.selected_discount['bands'])
  }

  updateDiscount(){
    this.discountForm.value['discountType'] = this.discount_type_element.nativeElement.innerHTML;
    console.log(this.discountForm.value)
    console.log(this.selected_discount['bands'])
    Swal.fire({
      title: "Confirmation.",
      text: "Proceed to edit this Discount?",
      showCancelButton: true,
      showConfirmButton: true,
      customClass: {
        cancelButton: "btn btn-danger",
        confirmButton: "btn btn-success mr-1",
      },
      cancelButtonText: "CANCEL",
      confirmButtonText: "PROCEED",
      buttonsStyling: false
    })
    .then(result =>{
      if(result.isConfirmed === true){
        switch (this.discount_type_element.nativeElement.innerHTML){
          case 'Product Material Discount': {
            this.postauth.editDiscount(this.discountForm.value).subscribe(suc => {
              console.log(suc);
              if(suc['response']==true){
                //notify success
                Swal.fire({
                  toast: true,
                  timer: 5000,
                  position: 'top-right',
                  title: "Success.",
                  text: "Discount edit successful",
                  icon: "success",
                  customClass: {confirmButton: "btn btn-danger mr-1",},
                  buttonsStyling: false
                });
                const index = this.activediscounts.findIndex(discount => discount['id'] === this.discountForm.value['id'])
                console.log('i want to see index')
                console.log(index)
                if(index !== -1){
                  this.activediscounts_filtered[index].name = this.discountForm.value['name']
                  this.activediscounts_filtered[index].discountType = this.discountForm.value['discountType']
                  this.activediscounts_filtered[index].rewardType = this.discountForm.value['rewardType']
                  this.activediscounts_filtered[index].rewardValue = this.discountForm.value['rewardValue']
                }
                this.discountForm.reset();
              }
              else{
                Swal.fire({
                  toast: true,
                  timer: 5000,
                  position: 'top-right',
                  title: "Error.",
                  text: "Error editing discount.Try again!",
                  icon: "warning",
                  customClass: {confirmButton: "btn btn-danger mr-1",},
                  buttonsStyling: false
                });
              }
            }, error => { 
              this.notiservice.notify('error',"Error editing  discount");
            });
            break;
          }
          case 'Material Discount': {
            this.postauth.editDiscount(this.discountForm.value).subscribe(suc => {
              console.log(suc);
              if(suc['response']==true){
                //notify success
                Swal.fire({
                  toast: true,
                  timer: 5000,
                  position: 'top-right',
                  title: "Success.",
                  text: "Discount edit successful",
                  icon: "success",
                  customClass: {confirmButton: "btn btn-danger mr-1",},
                  buttonsStyling: false
                });
                const index = this.activediscounts.findIndex(discount => discount['id'] === this.discountForm.value['id'])
                console.log('i want to see index')
                console.log(index)
                if(index !== -1){
                  this.activediscounts_filtered[index].name = this.discountForm.value['name']
                  this.activediscounts_filtered[index].discountType = this.discountForm.value['discountType']
                  this.activediscounts_filtered[index].rewardType = this.discountForm.value['rewardType']
                  this.activediscounts_filtered[index].rewardValue = this.discountForm.value['rewardValue']
                }
                this.discountForm.reset();
              }
              else{
                Swal.fire({
                  toast: true,
                  timer: 5000,
                  position: 'top-right',
                  title: "Error.",
                  text: "Error editing discount.Try again!",
                  icon: "warning",
                  customClass: {confirmButton: "btn btn-danger mr-1",},
                  buttonsStyling: false
                });
              }
            }, error => { 
              this.notiservice.notify('error',"Error editing  discount");
            });
            break;
          }
          case 'Fixed Discount': {
            this.postauth.editDiscount(this.discountForm.value).subscribe(suc => {
              console.log(suc);
              if(suc['response']==true){
                //notify success
                Swal.fire({
                  toast: true,
                  timer: 5000,
                  position: 'top-right',
                  title: "Success.",
                  text: "Discount edit successful",
                  icon: "success",
                  customClass: {confirmButton: "btn btn-danger mr-1",},
                  buttonsStyling: false
                });
                 const index = this.activediscounts.findIndex(discount => discount['id'] === this.discountForm.value['id'])
                console.log('i want to see index')
                console.log(index)
                if(index !== -1){
                  this.activediscounts_filtered[index].name = this.discountForm.value['name']
                  this.activediscounts_filtered[index].discountType = this.discountForm.value['discountType']
                  this.activediscounts_filtered[index].rewardType = this.discountForm.value['rewardType']
                  console.log(this.activediscounts_filtered[index].rewardType)
                  this.activediscounts_filtered[index].rewardValue = this.discountForm.value['rewardValue']
                }
                this.discountForm.reset();
              }
              else{
                Swal.fire({
                  toast: true,
                  timer: 5000,
                  position: 'top-right',
                  title: "Error.",
                  text: "Error editing discount.Try again!",
                  icon: "warning",
                  customClass: {confirmButton: "btn btn-danger mr-1",},
                  buttonsStyling: false
                });
              }
            }, error => { 
              this.notiservice.notify('error',"Error editing  discount");
            });
            break;
          }
          case 'Quantity Discount': {
            let discountwithband ={ discount: this.discountForm.value, bands: this.selected_discount['bands'] }
            this.postauth.editDiscount(discountwithband).subscribe(suc => {
              console.log(suc);
              if(suc['response']==true){
                //notify success
                Swal.fire({
                  toast: true,
                  timer: 5000,
                  position: 'top-right',
                  title: "Success.",
                  text: "Discount edit successful",
                  icon: "success",
                  customClass: {confirmButton: "btn btn-danger mr-1",},
                  buttonsStyling: false
                });
                const index = this.activediscounts.findIndex(discount => discount['id'] === this.discountForm.value['id'])
                console.log('i want to see index')
                console.log(index)
                if(index !== -1){
                  this.activediscounts_filtered[index].name = this.discountForm.value['name']
                  this.activediscounts_filtered[index].discountType = this.discountForm.value['discountType']
                  this.activediscounts_filtered[index].rewardType = this.discountForm.value['rewardType']
                  this.activediscounts_filtered[index].rewardValue = this.discountForm.value['rewardValue']
                }
                this.discountForm.reset();
              }
              else{
                Swal.fire({
                  toast: true,
                  timer: 5000,
                  position: 'top-right',
                  title: "Error.",
                  text: "Error editing discount.Try again!",
                  icon: "warning",
                  customClass: {confirmButton: "btn btn-danger mr-1",},
                  buttonsStyling: false
                });
              }
            }, error => { 
              this.notiservice.notify('error',"Error editing  discount");
            });
            break;
          }
          default: {
            console.log('default')
          }
          
        }
      }
    })
  }

}
