export interface Portalaccount {
  session: string;
  manufacturecode?: string;
  username: string;
  firstname?: string;
  lastname?: string;
  assignedData: {};
  authenticated?: boolean;
  powerbitoken?: {};
  defaultdashboard?: Array<any>;
  reportList?: Array<any>,
  datasetid?: string,
  selectedpowerbidashboard?: {},
  salesforcemodulestatus?: boolean

}
export enum DiscountTypes {
  FIXED_DISCOUNT,
  MATERIAL_DISCOUNT,
  QUATITY_DISCOUNT,

}
export enum DiscountValueTypes {
  PERCENTAGE_DISCOUNT,
  ACTUAL_VALUE_DISCOUNT

}

export enum DiscounTriggerTypes {
  __,
  item_quantity,
  item_sale_value,
  product_quantity,
  product_sale_value,
  order_quantity,
  order_sale_value,
  product_number,
  item_number

}

export enum DiscounRewardTypes {
  
  product,
  actual_value,
  percentage,
  otm_loyalty_points,
  manufacturer_loyalty_points
 }



export interface Discount<T> {
  getDiscount(): T;
}
export class FixedDiscount implements Discount<FixedDiscount>{
  manufacturerCode: string;
  id: string;
  discountValue: number;
  discountType: DiscountValueTypes;
  getDiscount(): FixedDiscount {
    return this;
  }

}
export interface QuantityDiscountEntry {
  discount: FixedDiscount;
  lowLimit: number;
  highLimit: number;
  name: string;
}
export class QuantityDiscount implements Discount<QuantityDiscount>{
  manufacturerCode: string;
  id: string;
  matrix?: Array<QuantityDiscountEntry>;
  constructor(){
    this.matrix=new Array<QuantityDiscountEntry>();
  }

  getDiscount(): QuantityDiscount {
   return this;
  }
}



