import { PostauthService } from './../../services/postauth.service';
import { Component, OnInit } from "@angular/core";

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  rtlTitle: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  rtlTitle: string;
  rtlSmallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  smallTitle?: string;
  rtlSmallTitle?: string;
  title?: string;
  rtlTitle: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard/powerbi",
    title: "Dashboard",
    type: "link",
    icontype: "tim-icons icon-chart-pie-36",
    rtlTitle: "لوحة القيادة"
  },
  {
    path: "/reports",
    title: "Business Inteligence",
    type: "sub",
    icontype: "tim-icons icon-image-02",
    collapse: "pages",
    rtlTitle: "صفحات",
    isCollapsed: true,
    children: [
      {
        path: "sales",
        rtlTitle: " التسعير ",
        rtlSmallTitle: "ع ",
        title: "Reports",
        type: "link",
        smallTitle: "P"
      },
    
   
      {
        path: "power_dashboard",
        rtlTitle: " تسجيل الدخول ",
        rtlSmallTitle: " هعذا",
        title: "DashBoards",
        type: "link",
        smallTitle: "L"
      } 
    ]
    /**
     *  children: [
      {
        path: "sales",
        rtlTitle: " التسعير ",
        rtlSmallTitle: "ع ",
        title: "Reports",
        type: "link",
        smallTitle: "P"
      },
    
      {
        path: "timeline",
        rtlTitle: "الجدول الزمني ",
        rtlSmallTitle: " ت",
        title: "Timeline",
        type: "link",
        smallTitle: "T"
      },
      {
        path: "login",
        rtlTitle: " تسجيل الدخول ",
        rtlSmallTitle: " هعذا",
        title: "Login",
        type: "link",
        smallTitle: "L"
      },
      {
        path: "register",
        rtlTitle: " تسجيل",
        rtlSmallTitle: "ص ",
        title: "Register",
        type: "link",
        smallTitle: "R"
      },
      {
        path: "lock",
        rtlTitle: "اقفل الشاشة ",
        rtlSmallTitle: "هذاع ",
        title: "Lock Screen",
        type: "link",
        smallTitle: "LS"
      },
      {
        path: "profile",
        rtlTitle: "ملف تعريفي للمستخدم",
        rtlSmallTitle: " شع",
        title: "User Profile",
        type: "link",
        smallTitle: "UP"
      }
    ]
     * 
     */
  }
  ,
  //saleforce
  // {
  //   path: "user-profile",
  //   title: "SalesForce",
  //   type: "link",
  //   icontype: "tim-icons icon-single-02",
  //   rtlTitle: "لوحة القيادة"
  //  },
  {
    path: "user-profile",
    title: "Profile",
    type: "link",
    icontype: "tim-icons icon-single-02",
    rtlTitle: "لوحة القيادة"
   },
  //  {
  //   path: "salesrep",
  //   title: "Sales Reps",
  //   type: "link",
  //   icontype: "tim-icons icon-single-02",
  //   rtlTitle: "لوحة القيادة"
  //  },
   {
    path: "/dashboard/salesforce",
    title: "Field Team management",
    type: "sub",
    icontype: "tim-icons icon-atom",
    collapse: "pages",
    rtlTitle: "صفحات",
    isCollapsed: true,
    children: [
      {
        path: "salesrep",
        title: "Members",
        type: "link",
        smallTitle: "S",
        rtlTitle: "لوحة القيادة",
        rtlSmallTitle: "ع ",
       },

      //  {
      //   path: "products",
      //   title: "Products",
      //   type: "link",
      //   smallTitle: "S",
      //   rtlTitle: "لوحة القيادة",
      //   rtlSmallTitle: "ع ",
      //  },
      //  {
      //   path: "pricebands",
      //   title: "price bands",
      //   type: "link",
      //   smallTitle: "S",
      //   rtlTitle: "لوحة القيادة",
      //   rtlSmallTitle: "ع ",
      //  },
      //  {
      //   path: "addpriceband",
      //   title: "price band",
      //   type: "link",
      //   smallTitle: "S",
      //   rtlTitle: "لوحة القيادة",
      //   rtlSmallTitle: "ع ",
      //  },
      //  {
      //   path: "regions",
      //   title: "Manage Regions",
      //   type: "link",
      //   smallTitle: "S",
      //   rtlTitle: "لوحة القيادة",
      //   rtlSmallTitle: "ع ",
      //   children: [
      //             {
      //               path: "createregion",
      //               title:"Create region",
      //               rtlTitle: "مثال ",
      //               rtlSmallTitle: "ش ",
      //               type: "link",
      //               smallTitle: "B"
      //             },
      //             {
      //               path: "regions",
      //               rtlTitle: "مثال ",
      //               rtlSmallTitle: "ش ",
      //               title: "Manage",
      //               type: "link",
      //               smallTitle: "B"
      //             }
      //           ]
      //  },
      //  {
      //   path: "createregion",
      //   title:"Create region",
      //   rtlTitle: "مثال ",
      //   rtlSmallTitle: "ش ",
      //   type: "link",
      //   smallTitle: "S"
      // },
      // {
      //   path: "distributionpartners",
      //   title:"Distribution Partners",
      //   rtlTitle: "مثال ",
      //   rtlSmallTitle: "ش ",
      //   type: "link",
      //   smallTitle: "S"
      // }

    ]},
    
    //product management
    {
    path: "/dashboard/products",
    title: "Product Management",
    type: "sub",
    icontype: "tim-icons icon-app",
    collapse: "pages",
    rtlTitle: "صفحات",
    isCollapsed: true,
    children: [
   /*   {
        path: "products",
        title: "Products",
        type: "link",
        smallTitle: "S",
        rtlTitle: "لوحة القيادة",
        rtlSmallTitle: "ع ",
       },
       
       {
        path: "pricebands",
        title: "price bands",
        type: "link",
        smallTitle: "S",
        rtlTitle: "لوحة القيادة",
        rtlSmallTitle: "ع ",
       },
       */
       {
        path: "edit",
        title: "My Products",
        type: "link",
        smallTitle: "S",
        rtlTitle: "لوحة القيادة",
        rtlSmallTitle: "ع ",
       },
       {
        path: "focus",
        title: "Focus Brands",
        type: "link",
        smallTitle: "S",
        rtlTitle: "لوحة القيادة",
        rtlSmallTitle: "ع ",
       },
      ]
    },
    {
      path: "/promotions",
      title: "Promotions",
      type: "sub",
      icontype: "tim-icons icon-app",
      collapse: "pages",
      rtlTitle: "صفحات",
      isCollapsed: true,
      children: [
        {
          path: "discounts",
          title: "Discounts",
          type: "link",
          smallTitle: "FD",
          rtlTitle: "لوحة القيادة",
          rtlSmallTitle: "ع ",
         },
         
        
         
      
        ]
      },

    //Manufacturer distribution
    {
    path: "/dashboard/distribution",
    title: "Direct Distribution",
    type: "sub",
    icontype: "tim-icons icon-delivery-fast",
    collapse: "pages",
    rtlTitle: "صفحات",
    isCollapsed: true,
    children: [
      {
        path: "#",
        title: "Regions",
        type: "sub",
        smallTitle: "S",
        collapse:"pages",
        isCollapsed:true,
        rtlTitle: "لوحة القيادة",
        rtlSmallTitle: "ع ",
        children: [
       
          {
            path: "regions",
            rtlTitle: "مثال ",
            rtlSmallTitle: "ش ",
            title: "List Regions",
            type: "link",
            smallTitle: "B"
          }
        ]
       },
       {
        path: "distributionpartners",
        title:"Partner  Management",
        rtlTitle: "مثال ",
        rtlSmallTitle: "ش ",
        type: "sub",
        isCollapsed:true,
        smallTitle: "S",
        children:[
      
          {
            path: "fulfillment_partners",
            title:"View Partners",
            rtlTitle: "مثال ",
            rtlSmallTitle: "ش ",
            type: "link",
            smallTitle: "S"
          }
        ]
      },
      {
        path: "distributiontheming",
        title:"App Customization",
        rtlTitle: "مثال ",
        rtlSmallTitle: "ش ",
        type: "link",
        isCollapsed:true,
        smallTitle: "S",
        children:[]
      },
      {
        path: "smsconfiguration",
        title:"SMS GATEWAY",
        rtlTitle: "مثال ",
        rtlSmallTitle: "ش ",
        type: "link",
        isCollapsed:true,
        smallTitle: "S",
        children:[]
      },
    
      {
        path: "#",
        title:"Customer Management",
        rtlTitle: "مثال ",
        rtlSmallTitle: "ش ",
        type: "sub",
        smallTitle: "S",
        isCollapsed:true,
        children:[
      {
        path: "customers",
        title:"Customer",
        rtlTitle: "مثال ",
        rtlSmallTitle: "ش ",
        type: "link",
        smallTitle: "S"
      },
      {
        path: "groups",
        title:"Groups",
        rtlTitle: "مثال ",
        rtlSmallTitle: "ش ",
        type: "link",
        smallTitle: "S"
      }
        ]}
    
    ]
    },
    {
      path: "/dashboard/pricelisting",
      title: "Price lists",
      type: "sub",
      icontype: "tim-icons icon-paper",
      collapse: "pages",
      rtlTitle: "صفحات",
      isCollapsed: true,
      children: [
        // {
        //   path: "master",
        //   title: "Master price list",
        //   type: "link",
        //   smallTitle: "S",
        //   rtlTitle: "لوحة القيادة",
        //   rtlSmallTitle: "ع ",
        // },
        {
          path: "others",
          title: "View Price List",
          type: "link",
          smallTitle: "S",
          rtlTitle: "لوحة القيادة",
          rtlSmallTitle: "ع ",
        },
        {
          path: "new",
          title: "Create pricelist",
          type: "link",
          smallTitle: "S",
          rtlTitle: "لوحة القيادة",
          rtlSmallTitle: "ع ",
        }
      ]
    }
  
  //  {
  //   path: "/components",
  //   title: "Components",
  //   type: "sub",
  //   icontype: "tim-icons icon-molecule-40",
  //   collapse: "components",
  //   isCollapsed: true,
  //   rtlTitle: "  المكونات",
  //   children: [
  //     {
  //       path: "multilevel",
  //       isCollapsed: true,
  //       title: "Multilevel Collapse",
  //       type: "sub",
  //       smallTitle: "MLT",
  //       rtlTitle: "انهيار متعدد المستويات",
  //       rtlSmallTitle: " ر",
  //       collapse: "multilevel",
  //       children: [
  //         {
  //           path: "buttons",
  //           rtlTitle: "مثال ",
  //           rtlSmallTitle: "ش ",
  //           title: "Buttons",
  //           type: "link",
  //           smallTitle: "B"
  //         }
  //       ]
  //     },
  //     {
  //       path: "buttons",
  //       rtlTitle: "مثال ",
  //       rtlSmallTitle: "ش ",
  //       title: "Buttons",
  //       type: "link",
  //       smallTitle: "B"
  //     },
  //     {
  //       path: "grid",
  //       rtlTitle: " نظام الشبكة ",
  //       rtlSmallTitle: "زو ",
  //       title: "Grid System",
  //       type: "link",
  //       smallTitle: "GS"
  //     },
  //     {
  //       path: "panels",
  //       rtlTitle: " لوحات ",
  //       rtlSmallTitle: "ع",
  //       title: "Panels",
  //       type: "link",
  //       smallTitle: "P"
  //     },
  //     {
  //       path: "sweet-alert",
  //       rtlTitle: "التنبيه الحلو ",
  //       rtlSmallTitle: " ومن",
  //       title: "Sweet Alert",
  //       type: "link",
  //       smallTitle: "SA"
  //     },
  //     {
  //       path: "notifications",
  //       rtlTitle: "إخطارات ",
  //       rtlSmallTitle: "ن ",
  //       title: "Notifications",
  //       type: "link",
  //       smallTitle: "N"
  //     },
  //     {
  //       path: "icons",
  //       rtlTitle: "الرموز ",
  //       rtlSmallTitle: " و",
  //       title: "Icons",
  //       type: "link",
  //       smallTitle: "I"
  //     },
  //     {
  //       path: "typography",
  //       rtlTitle: "طباعة ",
  //       rtlSmallTitle: " ر",
  //       title: "Typography",
  //       type: "link",
  //       smallTitle: "T"
  //     },
  //     {
  //       path: "userprof",
  //       rtlTitle: "طباعة ",
  //       rtlSmallTitle: " ر",
  //       title: "user",
  //       type: "link",
  //       smallTitle: "T"
  //     }
  //   ]
  // },

];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  reportname:Array<any>=new Array();

  constructor(public postauth: PostauthService) {}

  ngOnInit() {
    this. listReports();
    
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  
  }

  listReports(){
    this.postauth.currentUser.reportList.forEach(
      (element) => {     
            let report =element;
            this.reportname.push(report) 
            console.log(report);   
            console.log(this.reportname) 
           })
 
  }

  addedto(){
    for(var x =0; x < ROUTES.length; x++){
      if(ROUTES[x].title==="Power-BI Dashboards" &&ROUTES[x].type ==="sub"){
        let children: Array<ChildrenItems> =new Array();
        children = ROUTES[x].children;
        for(var j =0; j<children.length; j++){
            let child:ChildrenItems ={
             title:this.reportname["name"],
             path:"rtl",
             rtlTitle: " التسعير ",
             rtlSmallTitle: "ع ",                    
             type: "link",
             smallTitle: "P"
            };

          children.push(child)

         console.log(children[j].title)
        }
      }
    
    }
  }
}
